

const Footer = () => {
return (
<>
<footer className=" bg-light-red pt-10">
<div className="bg-white">
   <div className="container pt-4 pb-4">
      <div className="text-center">
         <p className="mb-2 mb-lg-0 fs-15">roguerecruit© 2023. All rights reserved.</p>
         
      </div>
   </div>
</div>
</footer>
</>
);
}
export default Footer;
